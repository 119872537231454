
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Mail } from 'react-feather'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WorldMap from "../components/worldmap"
import Investors from "../components/investors"

export default class RemoteInvestors extends React.Component {


  render() {
    return (
      <Layout>

        <SEO title="Raise Funds" description="Traditional venture capital firms have been hesitant to invest in remote and foreign founders — however the landscape has significantly changed with notable emerging firms interested in founders with non-traditional backgrounds and business models." />

        <div id="landing-top">
          <div id="contains-map">
            <div id="map-container">
              <WorldMap />
            </div>
          </div>
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
          <Mic className="page-icon" />
        </div> */}
              <h1 id="interviews-header">Raise Funds</h1>
              <h2 className="community-description">Traditional venture capital firms have been hesitant to invest in remote and foreign founders — however the landscape has significantly changed with notable emerging firms interested in founders with non-traditional backgrounds and business models.</h2>

            </div>
          </div>
          <div id="right-col">
            <OutboundLink href="mailto:hi@immigrantfounders.com" target="_blank">
              <div className="long-container colled" >

                <div className="item-header">
                  <div className="long-icon-container">
                    <Mail className="slack-icon" />
                  </div>
          Need Help?
            <div className="item-arrow">
                    →
            </div>
                </div>
                <div className="item-description">
                </div>

              </div>
            </OutboundLink>
          </div>
        </div>
        <Investors />
      </Layout>
    )
  }
}
