import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Investor from "../components/investor"
import OneWayVC from "../images/onewayvc.png"
import RemoteFirstCapital from "../images/remote_first_capital.png"
import UnshackledVC from "../images/unshackled_vc.png"
import TinySeed from "../images/tinyseed.png"
import EarnestCapital from "../images/earnest_capital.png"
import FundraiseFromHome from "../images/fundraise_from_home.png"


const Investors = ({ children }) => {
  return (

    <div className="investors-container">

      <Investor
        logo={OneWayVC}
        url="https://onewayvc.com"
        title="One Way VC"
        description="We invest in companies founded and co-founded by immigrants. Everyone on our investment team is an immigrant, so we understand the challenges of founding and scaling a company through this lense. We offer visa/legal support and references, as well as access to a network of mission-aligned industry experts and executives, many of whom are immigrants themselves."

        speciality_1="Sector Agnostic"
        speciality_2=""
      />


      <Investor
        logo={UnshackledVC}
        url="https://unshackledvc.com"
        title="Unshackled VC"
        description="We've started companies as immigrants—we get it. Our own journeys inspired us to create Unshackled Ventures, so that we could remove the bottlenecks of immigration and amplify your superpowers. We lead investments and provide the 'friends and family' capital immigrant teams often don't have."

        speciality_1="Sector Agnostic"
        speciality_2=""
      />

      <Investor
        logo={RemoteFirstCapital}
        url="https://remotefirstcapital.com"
        title="Remote First Capital"
        description="The future of global work will happen.
            And if we can, we'd like to help to make it happen sooner. We're a group of remote founders, operators and early investors investing in the next generation of remote work. We invest in companies in the USA and globally including Africa, Latin America, Asia and the rest of the world."

        speciality_1="Remote Tools"
        speciality_2=""
      />

      <Investor
        logo={EarnestCapital}
        url="https://earnestcapital.com"
        title="Earnest Capital"
        description="Earnest Capital provides early-stage funding, resources and a network of experienced advisors to founders building sustainable profitable businesses. We invest via a Shared Earnings Agreement, a new investment model developed transparently with the founder community."
        speciality_1="SaaS"
        speciality_2=""
      />

      <Investor
        logo={TinySeed}
        url="https://tinyseed.com"
        title="TinySeed"
        description="TinySeed is an accelerator, but it’s remote, runs for a full year, focuses on SaaS, and “non-unicorns” (companies that don’t aspire to grow at all costs to reach a $1B valuation). To venture capitalists, a company doing $10m in annual revenue is a miss. To us, it’s a great business. "

        speciality_1="SaaS"
        speciality_2=""
      />

      <Investor
        logo={FundraiseFromHome}
        url="https://fundraisefromhome.com"
        title="Fundraise From Home"
        description=" Fundraise From Home is a platform to pitch to a group of early stage investors, but does not guarantee investment. Interested investors follow-up with founders after they pitch with next steps. This program is for early-stage companies (pre-seed or seed stage), ideally started within the last year."

        speciality_1="Sector Agnostic"
        speciality_2=""
      />

    </div>)
}

export default Investors
