import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


const Investor = (props) => {

        
    return (

        <OutboundLink href={props.url} target="_blank">
            <div className="investor-container">
            <div className="investor-inner">
            <div className="investor-logo">
                <img src={props.logo} alt="Logo" />
            </div>
            <div className="investor-header">
            {props.title}
            </div>
            <div className="investor-description">
            <p>{props.description}</p>
            </div>
            <div className="investor-details">
            <div className="left-details">
            <div className="job-tags">
                <div className="job-tag">
                <div className="job-tag-text">
                    {props.speciality_1}
                </div>
                </div>
                </div>
                
            </div>
            </div>
            </div>
            </div>
        </OutboundLink>
    )
}

export default Investor